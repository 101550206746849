/* Basic setup */
.logistics-container {
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    /* background-image: url('/path/to/dotted-background.png'); Path to your background image */
  }
  
  .logistics-title {
    font-size: 5rem;
    margin-bottom: 20px;
    color: #333;
  }
 
  /* Grid layout for steps */
  .logistics-steps {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
    padding: 20px;
  }
  
  /* Individual step cards with gradient borders */
  .step-card {
    background: #ffffff;
    border: 1px solid transparent;
    border-image: linear-gradient(45deg, rgba(255, 103, 0, 0.8), rgba(0, 103, 255, 0.8)) 1;
    padding: 15px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .step-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .step-number {
    position: absolute;
    top: -1rem;
    right: -1rem;
    background-color: #ff6700;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }
  
  .step-title {
    font-size: 18px;
    color: #333;
    margin-top: 12px;
  }
  
  .step-description {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .logistics-steps {
      grid-template-columns: 1fr;
    }
   
  }
  