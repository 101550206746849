
.logistic-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    background-color: #122649; /* Adjust as needed */
  }
  
  .logistic-heading {
    font-size: 3.5rem;
    color: #f0a31e ;
    margin-bottom: 20px;
    text-align: center;
}

  .logistic-card {
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    transition: transform 0.3s ease-in-out;
  }
  
  .logistic-card:hover {
    transform: scale(1.05);
  }
  
  .logistic-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .logistic-content {
    padding: 15px;
    text-align: center;
  }
  
  .logistic-content h3 {
    margin: 10px 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  .logistic-content p {
    font-size: 0.9rem;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .logistic-card {
      max-width: 90%;
    }
  }
  /* industry we serve  */
  
  
/* Basic Styling */
/* body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.logistics-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  /* margin: 0 auto; */
  gap: -10px;
  background-color: #122649;
}

.text-container {
  /* color: white; */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text-container h1 {
  color: #f0a31e;
}

.services {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 1rem;
}

.service {
  background-color: #f0f4f7;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
}

.service:hover {
  transform: translateY(-5px);
  background-color: #e3f2fd;
}

.service img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.service h3 {
  font-size: 1.25rem;
  margin-top: 10px;
  color: #f0a31e;
}

/* Image Section */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  position: relative;
  /* height: 100vh; */
}

.image-container img {
  width: 90%;
  height: 550px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.image-container img:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .logistics-section {
    flex-direction: column;
    text-align: center;
  }

  .services {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .image-container {
    margin-top: 20px;
  }
}
