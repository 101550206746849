/* WhyChooseUs.css */

.why-choose-us-container {
  display: flex;
  background-color: #122649;
  padding: 40px;
  border-radius: 8px;
  color: #fff;
}

.left-panel {
  flex: 1;
  margin-right: 20px;
}

.left-panel h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  /* z-index: -1; */
}

.items-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  padding: 15px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative; /* Required for absolute positioning of connector */
  transition: background-color 0.3s, border-left-color 0.3s;
}

.item-content {
  display: flex;
  align-items: center;
  /* position: relative;   Add this to position text above the line */
  z-index: 1; /* Ensure text is above connector line */
}

.connector-line {
  width: 100%; /* Full width to match the height of the item */
  height: 100%; /* Full height to match the left panel item */
  background-color: #f0a31e ; /* Color for the connector line */
  position: absolute;
  left: 100%; /* Position it to the right of the item */
  top: 0; /*Start from the top*/
  transform: translateX(0%); /* Align in the middle */
  z-index: 0; /* Ensure line is behind the text */
}
.item:hover {
  background-color: #f0a31e ;
}

.item.active {
  background-color: #f0a31e ; /* Highlight active item */
  border-left: 5px solid #f0a31e ;
  color: #fff;
}

.right-panel {
  flex: 2;
  background-color: #f0a31e ; /* Background color of the right panel */
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; /* To align the visual connection */
}

.content h3 {
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: bold;
}

.content p {
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 1.5;
}

.content-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Responsive Design Adjustments */

@media (max-width: 768px) {
  .why-choose-us-container {
    flex-direction: column;
  }

  .left-panel,
  .right-panel {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .content-image {
    max-width: 100%;
  }
}
